<template>
  <div v-if="permission.read_perm == 1">
    <v-row style="padding: 15px" no-gutters>
      <v-col cols="12" style="padding: 0 10px; text-align: left; margin: auto">
        <div class="d-flex justify-content-between align-center">
          <v-toolbar-title class="overline">
            <p
              style="
                font-size: 16px;
                margin: 0;
                font-weight: bold;
                color: indigo;
              "
            >
              Presensi
            </p>
          </v-toolbar-title>
        </div>
      </v-col>
      <v-col cols="12" style="padding: 0 10px">
        <div style="padding: 0 5px" class="d-flex align-center">
          <div class="d-flex flex-column" style="width: 120px">
            <v-col cols="12" style="padding: 0">
              <p
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Status Presensi
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <v-autocomplete
                :items="dropdown.attendance_status"
                item-text="name"
                item-value="id"
                return-id
                dense
                style="margin: 0"
                v-model="form.attendance_status"
                :disabled="!isEdit"
              ></v-autocomplete>
            </v-col>
          </div>
          <div
            v-if="permission.update_perm == 1"
            cols="12"
            style="padding: 0 10px; text-align: right"
          >
            <a
              v-if="!isEdit"
              href=""
              @click.prevent="editForm"
              style="font-size: 14px"
            >
              <v-icon small color="#1976d2">
                mdi-application-edit-outline
              </v-icon>
              Edit status
            </a>
            <div class="d-flex" v-else>
              <v-btn
                @click.prevent="saveStatus"
                type="button"
                rounded
                icon
                elevation="0"
                color="success"
                class="white--text font-weight-bold"
                style="font-size: 12px; width: 130px"
                :loading="loading"
              >
                <v-icon small color="success"> mdi-content-save </v-icon>
                Simpan
              </v-btn>
              <v-btn
                @click.prevent="editForm"
                type="button"
                rounded
                icon
                elevation="0"
                color="error"
                class="white--text font-weight-bold"
                style="font-size: 12px; width: 130px"
                :loading="loading"
              >
                Batal
                <v-icon small color="error"> mdi-close </v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <br />
        <div class="d-flex justify-content-between align-center">
          <div class="d-flex">
            <div style="width: 140px; margin-right: 20px">
              <v-text-field
                class="attendance-start-date ml-1 mr-2"
                dense
                label="Start Date"
                type="date"
                name="startdate"
                style="height: 30px"
                step="1"
                v-model="paramAPI.start_date"
              />
            </div>

            <div style="width: 120px; margin-right: 10px">
              <v-text-field
                class="attendance-start-date ml-1 mr-2"
                dense
                label="End Date"
                type="date"
                name="enddate"
                style="height: 30px"
                step="1"
                v-model="paramAPI.end_date"
              />
            </div>
          </div>
          <div>
            <v-btn
              small
              elevation="1"
              color="indigo"
              class="white--text"
              style="font-size: 12px; margin-right: 40px"
              @click="exportData"
            >
              Export
            </v-btn>
          </div>
        </div>

        <v-data-table
          mobile-breakpoint="0"
          fixed-header
          height="40vh"
          :headers="headers"
          style="cursor: pointer"
          :items="response"
          :loading="loading"
          @click:row="rowClick"
          item-key="id"
          :options.sync="options"
          :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
          }"
          :server-items-length="totalData"
          @update:page="updatePage"
          @update:items-per-page="updateItemPerPage"
        >
          <template v-slot:[`item.date`]="{ item }">
            <div
              style="
                font-size: 12px;
                padding: 0;
                display: flex;
                flex-direction: row;
              "
            >
              {{ convertDate(item.date) }}
            </div>
          </template>
          <template v-slot:[`item.clock_in`]="{ item }">
            <div
              @click.stop="viewImage([1, item.picture_in])"
              v-if="item.clock_in !== null"
              class="d-flex flex-row justify-start align-center"
              style="padding: 0"
            >
              <span style="margin-right: 2px">{{
                attendFrom(item.attend_from_in)
              }}</span>
              {{ convertDate2(item.date) }}
              {{ item.clock_in }}
              <div v-if="item.attend_from_in !== null" style="margin-left: 2px">
                <v-btn icon small>
                  <v-icon small color="primary">mdi-image</v-icon>
                </v-btn>
              </div>
            </div>
          </template>
          <template v-slot:[`item.clock_out`]="{ item }">
            <div
              @click.stop="viewImage([2, item.picture_out])"
              v-if="item.clock_out !== null"
              class="d-flex flex-row justify-start align-center"
              style="padding: 0"
            >
              <span style="margin-right: 2px">
                {{ attendFrom(item.attend_from_out) }}
              </span>
              {{ convertDate2(item.clock_out_date) }}
              {{ item.clock_out }}
              <div
                v-if="item.attend_from_out !== null"
                style="margin-left: 2px"
              >
                <v-btn icon small>
                  <v-icon small color="primary">mdi-image</v-icon>
                </v-btn>
              </div>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      :width="wWidth > 780 ? '80vw' : '100vw'"
      persistent
    >
      <v-form readonly ref="entryForm" style="position: relative">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Detail Presensi
          </v-card-title>

          <v-card-text
            v-if="detail !== null"
            class="d-flex justify-center align-center"
          >
            <v-row no-gutters>
              <v-col
                cols="12"
                style="padding: 0; position: relative; top: 10px"
              >
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Name
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field dense :value="detail.employee_name" />
                  </v-col>
                </div>
              </v-col>
              <v-col
                cols="12"
                style="padding: 0; position: relative; top: 10px"
              >
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      NIK
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field dense :value="detail.nik" />
                  </v-col>
                </div>
              </v-col>
              <v-col
                cols="12"
                style="padding: 0; position: relative; top: 10px"
              >
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Date
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      dense
                      type="date"
                      name="date"
                      step="1"
                      :value="detail.date"
                      append-icon=""
                      readonly
                    />
                  </v-col>
                </div>
              </v-col>

              <v-col
                cols="12"
                md="3"
                style="padding: 0; position: relative; top: 10px"
              >
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Clock In
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field dense :value="detail.clock_in" />
                  </v-col>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="3"
                style="padding: 0; position: relative; top: 10px"
              >
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Lokasi
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      v-if="detail.attend_from_in === 1"
                      :value="detail.attend_from_company_name_in"
                      dense
                      style="height: 55px; font-size: 12px"
                    />
                    <v-text-field
                      v-if="detail.attend_from_in === 2"
                      value="Lain-lain"
                      dense
                      style="height: 55px; font-size: 12px"
                    />
                    <v-text-field
                      v-if="detail.attend_from_in === 3"
                      value="Rumah"
                      dense
                      style="height: 55px; font-size: 12px"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="3"
                style="padding: 0; position: relative; top: 10px"
              >
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Note
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      v-if="detail.clock_in_note !== undefined"
                      :value="detail.clock_in_note"
                      dense
                      style="height: 55px; font-size: 12px"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="3"
                style="
                  padding: 0;
                  position: relative;
                  top: 10px;
                  margin-bottom: 10px;
                "
              >
                <div
                  style="padding: 0 10px; margin: auto"
                  class="d-flex flex-column"
                >
                  <a
                    href="/"
                    @click.prevent="imgDialog(1)"
                    style="font-size: 12px"
                  >
                    Lihat gambar</a
                  >
                  <a
                    :href="`https://www.google.com/maps/search/?api=1&query=${detail.clock_in_lt},${detail.clock_in_ln}`"
                    target="_blank"
                    style="font-size: 12px"
                  >
                    Buka google map</a
                  >
                </div>
              </v-col>

              <v-col
                cols="12"
                md="3"
                style="padding: 0; position: relative; top: 10px"
              >
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Clock Out
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field dense :value="detail.clock_out" />
                  </v-col>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="3"
                style="padding: 0; position: relative; top: 10px"
              >
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Lokasi
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      v-if="detail.attend_from_out === 1"
                      :value="detail.attend_from_company_name_out"
                      dense
                      style="height: 55px; font-size: 12px"
                    />
                    <v-text-field
                      v-if="detail.attend_from_out === 2"
                      value="Lain-lain"
                      dense
                      style="height: 55px; font-size: 12px"
                    />
                    <v-text-field
                      v-if="detail.attend_from_out === 3"
                      value="Rumah"
                      dense
                      style="height: 55px; font-size: 12px"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="3"
                style="padding: 0; position: relative; top: 10px"
              >
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Note
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      v-if="detail.clock_out_note !== undefined"
                      :value="detail.clock_out_note"
                      dense
                      style="height: 55px; font-size: 12px"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="3"
                style="padding: 0; position: relative; top: 10px"
              >
                <div
                  style="padding: 0 10px; margin: auto"
                  class="d-flex flex-column"
                >
                  <a
                    href="/"
                    @click.prevent="imgDialog(2)"
                    style="font-size: 12px"
                  >
                    Lihat gambar</a
                  >
                  <a
                    :href="`https://www.google.com/maps/search/?api=1&query=${detail.clock_out_lt},${detail.clock_out_ln}`"
                    target="_blank"
                    style="font-size: 12px"
                  >
                    Buka google map</a
                  >
                </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="error"
              text
              outlined
              @click="close()"
              :loading="loading"
            >
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="openImageDialog" persistent>
      <div style="position: relative">
        <v-img v-if="openImageDialog" :src="imgSrc" @error="imgError">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <v-btn
          color="black"
          fab
          x-small
          @click="openImageDialog = !openImageDialog"
          style="position: absolute; top: 10px; right: 10px"
        >
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import axios from 'axios'
import buildType from '../../../../services/buildType'
import * as XLSX from 'xlsx/xlsx.mjs'
export default {
  props: ['result', 'dropdown', 'permission', 'appLevel', 'userLevel'],
  data() {
    return {
      dialog: false,
      wWidth: window.innerWidth,
      attendance: buildType.apiURL('attendanceTrial'),
      build: process.env.VUE_APP_BUILD_TYPE,
      paramAPI: {
        page: 1,
        keyword: '',
        offset: 0,
        limit: 10,
        status: '',
        sortBy: 'name',
        sortType: 'asc',
        company_id: 0,
        department_id: '',
        itemsPerPage: 10,
        start_date: '',
        end_date: ''
      },
      totalData: 0,

      options: {
        page: 1,
        itemsPerPage: 10,
        sortDesc: []
      },

      headers: [
        {
          text: 'NIK',
          value: 'nik',
          align: 'left',
          sortable: false
        },
        {
          text: 'Name',
          value: 'employee_name',
          align: 'left',
          sortable: false
        },
        {
          text: 'Date',
          value: 'date',
          align: 'left',
          sortable: false
        },
        {
          text: 'Clock In',
          value: 'clock_in',
          align: 'left',
          sortable: false
        },
        {
          text: 'Clock Out',
          value: 'clock_out',
          align: 'left',
          sortable: false
        }
      ],
      response: [],
      loading: false,
      detail: null,
      openImageDialog: false,
      imgSrc: '',
      isEdit: false,
      form: {
        attendance_status: null
      }
    }
  },

  async mounted() {
    const d = new Date()
    // the day before today
    // d.setDate(d.getDate() - 1)
    // today
    d.setDate(d.getDate())
    const n = d.toLocaleDateString('id').split('/').reverse()
    if (n[1].length === 1 || n[2].length === 1) {
      if (n[1].length === 1) {
        n[1] = `0${n[1]}`
      }
      if (n[2].length === 1) {
        n[2] = `0${n[2]}`
      }
    }
    const joinedDate = n.join('-')
    this.paramAPI.start_date = joinedDate
    this.paramAPI.end_date = joinedDate

    if (this.userLevel.is_admin == 1) {
      if (this.result.nik !== this.getUserProfile.employee.nik) {
        this.paramAPI.keyword = this.result.nik
      }
    }

    await this.getDataFromApi()
  },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  watch: {
    'paramAPI.start_date'() {
      this.getDataFromApi()
    },
    'paramAPI.end_date'() {
      this.getDataFromApi()
    }
  },
  methods: {
    ...mapMutations(['setParamAsset']),

    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },

    close() {
      this.detail = null
      setTimeout(() => {
        this.dialog = false
      }, 100)
    },
    searchEnter() {
      this.getDataFromApi()
    },
    async getDataFromApi() {
      this.form.attendance_status = this.result.last_attendance_status
      this.loading = true
      await this.initDataTable().then((data) => {
        this.response = data.data
        this.totalData = data.total_record
        this.loading = false
      })
    },
    async initDataTable() {
      return await new Promise((resolve) => {
        this.response = []
        const url = `${this.attendance}report/attendance/detail?keyword=${
          this.paramAPI.keyword
        }&start_date=${this.paramAPI.start_date}&end_date=${
          this.paramAPI.end_date
        }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
          this.paramAPI.limit
        }&order_by=date&order_type=desc&company_id=${
          this.getUserProfile.employee.company.plant_id
        }&mode=`

        axios
          .get(url)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },
    editForm() {
      if (this.isEdit) {
        this.$emit('cancel')
      }
      this.isEdit = !this.isEdit
    },
    saveStatus() {
      const newForm = {
        id: this.$route.params.id,
        attendance_status:
          this.form.attendance_status !== 0 ? this.form.attendance_status : null
      }
      console.log(newForm)
      this.save(newForm)
    },
    async save(form) {
      this.loading = true
      await axios
        .post(
          `${this.attendance}employee/change_last_attendance_status`,
          form
        )
        .then((res) => {
          this.loading = false
          if (res.data.status_code !== '-99') {
            setTimeout(() => {
              this.$emit('cancel')
              return this.editForm()
            }, this.showMsgDialog('success', res.data.status_msg, false))
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              return this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              return this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch((err) => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          return (this.loading = false)
        })
    },
    rowClick(pItem) {
      this.detail = pItem
      setTimeout(() => {
        this.dialog = true
      }, 300)
    },
    onResize() {
      this.wWidth = window.innerWidth
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      this.getDataFromApi()
    },

    imgError() {
      // this.isImgError = true
      this.imgSrc =
        'https://e-sanqua.sanquawater.co.id/image/ecatalogue/products/photo_1/ProductPhoto-jahsors8yollrrbjwsyx7.png'
    },
    imgDialog(val) {
      if (val === 1) {
        if (process.env.VUE_APP_BUILD_TYPE === 'development') {
          this.imgSrc = `https://dev-esanqua.sanquawater.co.id/image/ecatalogue/hris/photo/attendance/${this.detail.picture_in}`
        } else {
          this.imgSrc = `https://e-sanqua.sanquawater.co.id/image/ecatalogue/hris/photo/attendance/${this.detail.picture_in}`
        }
      }
      if (val === 2) {
        if (process.env.VUE_APP_BUILD_TYPE === 'development') {
          this.imgSrc = `https://dev-esanqua.sanquawater.co.id/image/ecatalogue/hris/photo/attendance/${this.detail.picture_out}`
        } else {
          this.imgSrc = `https://e-sanqua.sanquawater.co.id/image/ecatalogue/hris/photo/attendance/${this.detail.picture_out}`
        }
      }
      setTimeout(() => {
        this.openImageDialog = true
      }, 300)
    },
    viewImage(pItem) {
      if (pItem[0] === 1) {
        if (process.env.VUE_APP_BUILD_TYPE === 'development') {
          this.imgSrc = `https://dev-esanqua.sanquawater.co.id/image/ecatalogue/hris/photo/attendance/${pItem[1]}`
        } else {
          this.imgSrc = `https://e-sanqua.sanquawater.co.id/image/ecatalogue/hris/photo/attendance/${pItem[1]}`
        }
      }
      if (pItem[0] === 2) {
        if (process.env.VUE_APP_BUILD_TYPE === 'development') {
          this.imgSrc = `https://dev-esanqua.sanquawater.co.id/image/ecatalogue/hris/photo/attendance/${pItem[1]}`
        } else {
          this.imgSrc = `https://e-sanqua.sanquawater.co.id/image/ecatalogue/hris/photo/attendance/${pItem[1]}`
        }
      }
      setTimeout(() => {
        this.openImageDialog = true
      }, 300)
    },
    convertDate(raw) {
      if (raw !== null) {
        const year = raw.slice(0, raw.indexOf('-'))
        const month = raw.slice(raw.indexOf('-') + 1, raw.lastIndexOf('-'))
        const day = raw.slice(raw.lastIndexOf('-') + 1)
        // return (day + '-' + month + '-' + year).replace(/-/g, '/')
        return day + '-' + month + '-' + year
      }
    },
    convertDate2(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = Intl.DateTimeFormat('id', {
          day: 'numeric',
          month: 'long'
        }).format(date)
        return local
      }
    },
    attendFrom(val) {
      switch (val) {
        case 1:
          return 'Kantor:'
        case 2:
          return 'Lain-lain:'
        case 3:
          return 'Rumah:'
        default:
          return 'Thermal:'
      }
    },

    async exportData() {
      console.log('eksport>>>', this.response)
      if (this.response.length > 0) {
        this.exportNow(this.response)
      } else {
        this.showMsgDialog('warning', 'No Data will be exported', false)
      }
    },
    exportNow(selectedData) {
      const arrData = []
      for (let i = 0; i < selectedData.length; i++) {
        const param = {
          nik: selectedData[i].nik,
          name: selectedData[i].employee_name,
          company: selectedData[i].company_name,
          date: selectedData[i].date,
          check_in: selectedData[i].date + ' ' + selectedData[i].clock_in,
          check_in_coordinate:
            selectedData[i].clock_in_lt + ', ' + selectedData[i].clock_in_ln,
          check_in_type: this.attendFrom(selectedData[i].attend_from_in),
          check_in_from_company: selectedData[i].attend_from_company_name_in,
          check_out:
            selectedData[i].clock_out_date + ' ' + selectedData[i].clock_out,
          check_out_coordinate:
            selectedData[i].clock_out_lt + ', ' + selectedData[i].clock_out_ln,
          check_out_type: this.attendFrom(selectedData[i].attend_from_out),
          check_out_from_company: selectedData[i].attend_from_company_name_out,
          body_temperature: selectedData[i].company
        }

        arrData.push(param)
      }
      this.downloadExcell(arrData)
    },
    downloadExcell(arrData) {
      const data = XLSX.utils.json_to_sheet(arrData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, data, 'data')
      XLSX.writeFile(wb, `Presensi_${arrData[0].name}_${arrData[0].date}.xlsx`)
    }
  }
}
</script>
