<template>
  <v-row v-if="permission.read_perm == 1" style="padding: 15px" no-gutters>
    <v-col cols="12" style="padding: 0 10px; text-align: left; margin: auto">
      <div class="d-flex justify-content-between align-center">
        <!-- <v-btn
          type="button"
          rounded
          elevation="0"
          color="success"
          class="white--text font-weight-bold"
          style="font-size:12px;"
          @click="exportData"
        >
          Export Report
          <v-icon style="margin-left:5px;" small>mdi-download</v-icon>
        </v-btn> -->
        <v-toolbar-title class="overline">
          <p
            style="font-size: 16px; margin: 0; font-weight: bold; color: indigo"
          >
            COP
          </p>
        </v-toolbar-title>
      </div>
    </v-col>
    <v-col cols="12" style="padding: 0 10px">
      <div flat class="d-flex justify-content-between align-center">
        <div style="margin-left: auto">
          <v-text-field
            v-model="paramAPI.keyword"
            label="Cari disini"
            type="search"
            outlined
            dense
            append-icon="mdi-magnify"
            style="position: relative; top: 15px"
            @keyup.enter="searchEnter"
            :disabled="loading"
          ></v-text-field>
        </div>
      </div>

      <v-data-table
        mobile-breakpoint="0"
        fixed-header
        height="20vh"
        :headers="headers"
        style="cursor: pointer"
        :items="response"
        :loading="loading"
        @click:row="rowClick"
        item-key="id"
        :options.sync="options"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
        }"
        :server-items-length="totalData"
        @update:page="updatePage"
        @update:items-per-page="updateItemPerPage"
      >
        <template v-slot:[`item.no`]="{ index }">
          <div
            style="
              font-size: 14px;
              padding: 0;
              display: flex;
              flex-direction: row;
            "
          >
            {{ index + 1 + paramAPI.offset * paramAPI.limit }}
          </div>
        </template>
        <template v-slot:[`item.employee.company_id`]="{ item }">
          <div
            :style="`font-size:14px; padding: 0; display: flex; flex-direction: row; justify-content: center; align-items:center;`"
          >
            {{ getCompanyName(item.employee.company_id) }}
          </div>
        </template>
        <template v-slot:[`item.amount`]="{ item }">
          <div
            :style="`font-size:14px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
          >
            Rp{{ priceConvert(item.amount) }}
          </div>
        </template>
        <template v-slot:[`item.installment`]="{ item }">
          <div
            :style="`font-size:14px; padding: 0; display: flex; flex-direction: row; justify-content: start; align-items:center;`"
          >
            Rp{{ priceConvert(item.installment) }}
          </div>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          <div
            style="
              font-size: 12px;
              padding: 0;
              display: flex;
              flex-direction: row;
            "
          >
            {{ convertDate(item.created_at) }}
          </div>
        </template>
        <template v-slot:[`item.updatedAt`]="{ item }">
          <div
            style="
              font-size: 12px;
              padding: 0;
              display: flex;
              flex-direction: row;
            "
          >
            {{ convertDate(item.updated_at) }}
          </div>
        </template>
      </v-data-table>
    </v-col>
    <v-col>
      <v-dialog
        v-if="detailInstalment !== null"
        transition="dialog-bottom-transition"
        max-width="800"
        v-model="copDialog"
      >
        <v-card tile>
          <v-toolbar flat dark color="primary">
            <v-btn icon dark @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title> DETAIL COP </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form
              readonly
              ref="entryForm1"
              @submit.prevent
              style="position: relative"
            >
              <v-row style="padding-top: 15px">
                <v-col cols="4" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Karyawan
                  </p>
                  <v-text-field
                    dense
                    :value="detailInstalment.employee.name"
                    readonly
                    style="height: 45px"
                  />
                </v-col>
                <v-col cols="4" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    NIK
                  </p>
                  <v-text-field
                    dense
                    :value="detailInstalment.employee.nik"
                    style="height: 45px"
                  />
                </v-col>
                <v-col cols="4" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Perusahaan
                  </p>
                  <v-text-field
                    dense
                    :value="
                      getCompanyName(detailInstalment.employee.company_id)
                    "
                    style="height: 45px"
                  />
                </v-col>

                <v-col cols="4" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Jumlah pinjaman
                  </p>
                  <v-text-field
                    dense
                    :value="'Rp' + priceConvert(detailInstalment.amount)"
                    style="height: 45px"
                  />
                </v-col>
                <v-col cols="4" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Cicilan per bulan
                    <!-- <span style="font-size: 8px;"
                    >(dipotong langsung ketika gajian)</span
                  > -->
                  </p>
                  <v-text-field
                    dense
                    :value="'Rp' + priceConvert(detailInstalment.installment)"
                    style="height: 45px"
                  />
                </v-col>
                <v-col cols="4" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Tanggal berlaku
                  </p>
                  <v-text-field
                    dense
                    :value="convertDate(detailInstalment.effective_date)"
                    style="height: 45px"
                  />
                </v-col>
                <v-col cols="12" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Keterangan
                  </p>
                  <v-textarea
                    rows="2"
                    dense
                    :value="detailInstalment.description"
                  />
                </v-col>
              </v-row>
            </v-form>
            <v-divider></v-divider>

            <v-card v-if="detailInstalment.cop_detail !== null">
              <v-card-title>DETAIL CICILAN<v-spacer></v-spacer> </v-card-title>
              <v-data-table
                :headers="copDetailHeader"
                :items="detailInstalment.cop_detail"
                :items-per-page="10000"
                mobile-breakpoint="0"
                fixed-header
                height="50vh"
                :footer-props="{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  itemsPerPageOptions: [50, 100]
                }"
              >
                <template v-slot:[`item.payment_amount`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    Rp{{ priceConvert(item.payment_amount) }}
                  </div>
                </template>
                <template v-slot:[`item.remaining_cop`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    Rp{{ priceConvert(item.remaining_cop) }}
                  </div>
                </template>
                <template v-slot:[`item.payment_date`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ convertDate(item.payment_date) }}
                  </div>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <div
                    :style="`font-size: 12px;
                          padding: 0;
                          display: flex;
                          flex-direction: row;
                          color:${
                            item.status === 1
                              ? 'green'
                              : item.status === 0
                              ? 'red'
                              : '-'
                          };`"
                  >
                    {{
                      item.status === 1
                        ? 'Sudah dibayar'
                        : item.status === 0
                        ? 'Belum dibayar'
                        : '-'
                    }}
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import buildType from '../../../../services/buildType'
export default {
  props: ['result', 'permission', 'appLevel', 'userLevel'],
  data() {
    return {
      hrsApi: buildType.apiURL('hrs'),
      build: process.env.VUE_APP_BUILD_TYPE,
      loading: true,
      paramAPI: {
        page: 1,
        keyword: '',
        offset: 0,
        limit: 10,
        status: '',
        sortBy: 'name',
        sortType: 'asc',
        company_id: 0,
        department_id: '',
        itemsPerPage: 10,
        date: ''
      },
      totalData: 0,

      options: {
        page: 1,
        itemsPerPage: 10,
        sortDesc: []
      },
      headers: [
        {
          text: 'No',
          value: 'no',
          align: 'left',
          sortable: false
        },
        {
          text: 'Karyawan',
          value: 'employee.name',
          sortable: false
        },
        {
          text: 'NIK',
          value: 'employee.nik',
          sortable: false
        },
        {
          text: 'Perusahaan',
          value: 'employee.company_id',
          sortable: false
        },
        {
          text: 'Jumlah Pinjaman',
          value: 'amount',
          sortable: false
        },
        {
          text: 'Cicilan',
          value: 'installment',
          sortable: false
        },
        {
          text: 'Dibuat pada',
          value: 'createdAt',
          align: 'left',
          sortable: false
        },
        {
          text: 'Diupdate pada',
          value: 'updatedAt',
          align: 'left',
          sortable: false
        },
        {
          text: 'Actions',
          value: 'action',
          align: 'left',
          sortable: false
        }
      ],
      copDetailHeader: [
        {
          text: 'Cicilan',
          value: 'installment_seq',
          sortable: false
        },
        {
          text: 'Jumlah dibayarkan',
          value: 'payment_amount',
          sortable: false
        },
        {
          text: 'Tgl. dibayarkan',
          value: 'payment_date',
          sortable: false
        },
        {
          text: 'Sisa hutang',
          value: 'remaining_cop',
          sortable: false
        },
        {
          text: 'Status',
          value: 'status',
          sortable: false
        }
      ],
      response: [],
      detailInstalment: null,
      copDialog: false
    }
  },

  async mounted() {
    // this.paramAPI.company_id = this.getUserProfile.employee.company_id
    await this.getDataFromApi()
  },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  methods: {
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },

    searchEnter() {
      this.getDataFromApi()
    },
    async getDataFromApi() {
      this.loading = true
      await this.initDataTable().then((data) => {
        this.response = data.data
        this.totalData = data.total_record
        this.loading = false
      })
    },
    async initDataTable() {
      const url = `${this.hrsApi}employee/cop/list?keyword=${
        this.result.nik
      }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
        this.paramAPI.limit
      }&company_id=${this.result.company.id}&employee_id=${this.result.id}`
      return await new Promise((resolve) => {
        axios
          .get(url)
          .then((res) => {
            console.log(res)
            resolve(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },
    rowClick(pItem) {
      this.detailInstalment = pItem
      setTimeout(() => {
        this.copDialog = true
      }, 100)
    },
    closeDialog() {
      this.detailInstalment = null
      setTimeout(() => {
        this.copDialog = false
      }, 300)
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleString('id')
        const slice = local.slice(0, local.lastIndexOf(','))
        return slice
      } else {
        return '-'
      }
    },

    getCompanyName(val) {
      let name = ''
      if (val != null) {
        switch (val) {
          case 1:
            name = 'PT TIRTA MAS PERKASA'
            break
          case 2:
            name = 'PT INDOMULTIMAS PERKASA'
            break
          case 3:
            name = 'PT GRAHAMAS INTITIRTA'
            break
          case 4:
            name = 'PT KENCANA ABADI JAYA'
            break
          case 5:
            name = 'PT WAHANA INTI MAS'
            break
          case 6:
            name = 'PT SANQUA MULTI INTERNASIONAL'
            break
          case 7:
            name = 'PT TALENTA HEBA PARNITA'
            break
          case 9:
            name = 'PT GOLDENPACK PERKASA'
            break
          case 10:
            name = 'PT TIRTA MAS PERKASA (BAWEN)'
            break
          case 12:
            name = 'PT BATARI PILAR SEMESTA'
            break
          case 13:
            name = 'PT BATARI PILAR CEMERLANG'
            break
          case 14:
            name = 'PT WAHANA INTI MAS (SAGI)'
            break
          case 15:
            name = 'PT KENCANA ABADI JAYA (BANYUWANGI)'
            break
          default:
            break
        }
      }

      return name
    },
    priceConvert(xVal) {
      const num = 0
      if (xVal !== undefined) {
        if (xVal !== null) {
          let round = xVal
          if (round.toString().includes('.')) {
            round = round.toFixed(2)
          }
          const val = round + ''
          let result = ''

          if (val.includes('.')) {
            const splitVal = val.split('.')
            const main = splitVal[0]
            const decimal = splitVal[1]
            result =
              main.toString().replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',') +
              `.${decimal}`
          } else {
            result = val
              .toString()
              .replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',')
          }
          return result
        }
      }
      return num
    },
    getCompanyAlias(id) {
      switch (id) {
        case 1:
          return 'TMP'
        case 2:
          return 'IMP'
        case 3:
          return 'GIT'
        case 4:
          return 'KAJ'
        case 5:
          return 'WIM'
        case 6:
          return 'SMI'
        case 7:
          return 'THP'
        case 8:
          return 'GAI'
        case 9:
          return 'GPP'
        case 10:
          return 'TMP2'
        case 11:
          return 'INO'
        case 12:
          return 'BPS'
        case 13:
          return 'BPC'
        case 14:
          return 'WIMS'
        case 15:
          return 'KAJB'
      }
    }
  }
}
</script>
