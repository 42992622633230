var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.permission.read_perm == 1)?_c('v-row',{staticStyle:{"padding":"15px"},attrs:{"no-gutters":""}},[_c('v-col',{staticStyle:{"padding":"0 10px","text-align":"left","margin":"auto"},attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-between align-center"},[_c('v-toolbar-title',{staticClass:"overline"},[_c('p',{staticStyle:{"font-size":"16px","margin":"0","font-weight":"bold","color":"indigo"}},[_vm._v(" Pengajuan Izin ")])]),(
          _vm.permission.create_perm == 1 &&
          _vm.result.nik === _vm.getUserProfile.employee.nik
        )?_c('router-link',{attrs:{"to":"/hr/permission/submission"}},[_c('v-btn',{staticClass:"white--text font-weight-bold",staticStyle:{"font-size":"12px"},attrs:{"type":"button","rounded":"","elevation":"0","color":"primary"}},[_vm._v(" Formulir Pengajuan Izin ")])],1):_vm._e()],1)]),_c('v-col',{staticStyle:{"padding":"0 10px"},attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-between align-center",attrs:{"flat":""}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticStyle:{"width":"220px"}},[_c('v-text-field',{staticStyle:{"position":"relative","top":"15px","font-size":"12px"},attrs:{"dense":"","outlined":"","type":"date","name":"date","step":"1"},model:{value:(_vm.paramAPI.date),callback:function ($$v) {_vm.$set(_vm.paramAPI, "date", $$v)},expression:"paramAPI.date"}})],1)]),_c('div',{staticStyle:{"margin-left":"20px"}},[_c('v-text-field',{staticStyle:{"position":"relative","top":"15px"},attrs:{"label":"Cari disini","type":"search","outlined":"","dense":"","append-icon":"mdi-magnify","disabled":_vm.loading},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchEnter($event)}},model:{value:(_vm.paramAPI.keyword),callback:function ($$v) {_vm.$set(_vm.paramAPI, "keyword", $$v)},expression:"paramAPI.keyword"}})],1)]),_c('v-data-table',{staticStyle:{"cursor":"pointer"},attrs:{"mobile-breakpoint":"0","fixed-header":"","min-height":"20vh","headers":_vm.headers,"items":_vm.response,"loading":_vm.loading,"item-key":"id","options":_vm.options,"footer-props":{
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
      },"server-items-length":_vm.totalData},on:{"click:row":_vm.rowClick,"update:options":function($event){_vm.options=$event},"update:page":_vm.updatePage,"update:items-per-page":_vm.updateItemPerPage},scopedSlots:_vm._u([{key:"item.employee",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticStyle:{"font-size":"12px","padding":"0","display":"flex","flex-direction":"row"}},[_vm._v(" "+_vm._s(item.employee.name)+" ")])]}},{key:"item.company",fn:function(ref){
      var item = ref.item;
return [(item.company !== null)?_c('div',{staticStyle:{"font-size":"12px","padding":"0","display":"flex","flex-direction":"row"}},[_vm._v(" "+_vm._s(item.company.name)+" ")]):_vm._e()]}},{key:"item.department",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticStyle:{"font-size":"12px","padding":"0","display":"flex","flex-direction":"row"}},[_vm._v(" "+_vm._s(item.department.name)+" ")])]}},{key:"item.permission_type",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticStyle:{"font-size":"12px","padding":"0","display":"flex","flex-direction":"row"}},[_vm._v(" "+_vm._s(item.permission_type.name)+" ")])]}},{key:"item.start_date",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticStyle:{"font-size":"12px","padding":"0","display":"flex","flex-direction":"row"}},[_vm._v(" "+_vm._s(_vm.convertDate(item.start_date))+" ")])]}},{key:"item.end_date",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticStyle:{"font-size":"12px","padding":"0","display":"flex","flex-direction":"row"}},[_vm._v(" "+_vm._s(_vm.convertDate(item.end_date))+" ")])]}},{key:"item.created_at",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticStyle:{"font-size":"12px","padding":"0","display":"flex","flex-direction":"row"}},[_vm._v(" "+_vm._s(_vm.convertDate(item.created_at))+" ")])]}},{key:"item.status_permission",fn:function(ref){
      var item = ref.item;
return [_c('div',{style:(("font-size:14px; padding: 0; display: flex; flex-direction: row; color:" + (_vm.statusColor(
            item.status_permission
          )) + ";"))},[_vm._v(" "+_vm._s(_vm.requestStatus(item.status_permission))+" ")])]}}],null,true)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }