<template>
  <div v-if="permission.read_perm == 1">
    <v-form
      v-if="result !== null"
      ref="entryForm"
      @submit.prevent="submit"
      lazy-validation
      :disabled="!isEdit"
    >
      <v-row style="padding: 15px" no-gutters>
        <v-col
          v-if="permission.update_perm == 1"
          cols="12"
          style="padding: 0 10px; text-align: right"
        >
          <a
            v-if="!isEdit"
            href=""
            @click.prevent="editForm"
            style="font-size: 14px"
          >
            <v-icon small color="#1976d2">
              mdi-application-edit-outline
            </v-icon>
            Edit info
          </a>
          <p
            v-else
            @click.prevent="editForm"
            style="font-size: 14px; margin: 0; color: red; cursor: pointer"
          >
            Batal
            <v-icon small color="red"> mdi-close </v-icon>
          </p>
        </v-col>
        <v-col cols="12" md="3" style="padding: 0 10px 10px 10px">
          <v-img
            v-if="!isEdit"
            :src="result.photo"
            lazy-src="https://cdn-icons-png.flaticon.com/512/61/61173.png"
            aspect-ratio="1"
            class="grey lighten-2"
          >
            <!-- <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template> -->
          </v-img>

          <div
            v-else
            style="
              padding: 10px;
              border: 2px dashed grey;
              position: absolute;
              top: 0;
              left: 10px;
              right: 10px;
              bottom: 10px;
            "
            class="d-flex flex-column justify-center align-center"
          >
            <div style="height: 45px; color: red; width: 100%">
              <v-file-input
                ref="fotoUpload"
                label="Pilih foto"
                dense
                outlined
                prepend-icon=""
                @change="uploadFoto"
              ></v-file-input>
            </div>
            <p style="margin: 0; width: 100%">
              {{ result.photo }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" md="9">
          <v-row no-gutters>
            <v-col cols="12" md="6" style="padding: 0 10px">
              <v-col cols="4" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Nama
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field dense v-model="result.name" />
              </v-col>
            </v-col>
            <v-col cols="12" md="6" style="padding: 0 10px">
              <v-col cols="4" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Email
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field dense v-model="result.email" />
              </v-col>
            </v-col>
            <v-col cols="12" md="6" style="padding: 0 10px">
              <v-col cols="4" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  No. KTP
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field dense v-model="result.no_identitas" />
              </v-col>
            </v-col>
            <!-- <v-col cols="12" md="6" style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
              >
                No. SMI
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0;">
              <v-text-field dense value="09874" />
            </v-col>
          </v-col>
          <v-col cols="12" md="6" style="padding: 0 10px">
            <v-col cols="4" style="padding: 0">
              <p
                style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
              >
                VIP. SMI
              </p>
            </v-col>
            <v-col cols="12" style="padding: 0;">
              <v-text-field dense value="09874-100001" />
            </v-col>
          </v-col> -->
            <v-col cols="12" md="6" style="padding: 0 10px">
              <v-col cols="4" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  No. Telepon
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field dense v-model="result.mobile_phone_no" />
              </v-col>
            </v-col>
            <v-col cols="12" md="6" style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Jenis Kelamin
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-autocomplete
                  :items="[
                    {
                      name: 'Laki-laki',
                      id: 1
                    },
                    {
                      name: 'Perempuan',
                      id: 2
                    }
                  ]"
                  item-text="name"
                  item-value="id"
                  return-id
                  dense
                  style="margin: 0"
                  v-model="result.gender.id"
                  :append-icon="!isEdit ? '' : '$dropdown'"
                ></v-autocomplete>
              </v-col>
            </v-col>
            <v-col cols="12" md="6" style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Agama
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-autocomplete
                  v-if="result.religion !== null"
                  :items="dropdown.religion"
                  item-text="name"
                  item-value="id"
                  return-id
                  dense
                  style="margin: 0"
                  v-model="result.religion.id"
                  :append-icon="!isEdit ? '' : '$dropdown'"
                ></v-autocomplete>
                <v-autocomplete
                  v-else
                  :items="dropdown.religion"
                  item-text="name"
                  item-value="id"
                  return-object
                  dense
                  style="margin: 0"
                  v-model="result.religion"
                  :append-icon="!isEdit ? '' : '$dropdown'"
                ></v-autocomplete>
              </v-col>
            </v-col>
            <v-col cols="12" md="4" style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Tempat
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field dense v-model="result.birth_place" />
              </v-col>
            </v-col>
            <v-col cols="12" md="4" style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Tgl. Lahir
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field
                  dense
                  v-model="result.birth_date"
                  type="date"
                  name="date"
                  step="1"
                />
              </v-col>
            </v-col>
            <v-col cols="12" md="4" style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Usia
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field dense :value="getAge()" />
              </v-col>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" style="padding: 0 10px"><br /></v-col>

        <v-col cols="12" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              Alamat Sesuai KTP
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-textarea v-model="result.address" dense rows="1" auto-grow />
          </v-col>
        </v-col>
        <v-col cols="12" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              Alamat Domisili
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-textarea
              v-model="result.domicile_address"
              dense
              rows="1"
              auto-grow
            />
          </v-col>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              Pendidikan
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-autocomplete
              :items="dropdown.education"
              dense
              style="margin: 0"
              v-model="result.education_level"
              clearable
              :append-icon="!isEdit ? '' : '$dropdown'"
            ></v-autocomplete>
          </v-col>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              Jurusan
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-text-field
              style="height: 30px; position: relative; top: -3px"
              dense
              v-model="result.education_major"
            />
          </v-col>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              Status Pernikahan
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-autocomplete
              :items="[
                {
                  name: 'Belum Menikah',
                  id: 1
                },
                {
                  name: 'Menikah',
                  id: 2
                },
                {
                  name: 'Cerai',
                  id: 3
                }
              ]"
              item-text="name"
              item-value="id"
              return-id
              dense
              style="margin: 0"
              v-model="result.mariage_status.id"
              clearable
              :append-icon="!isEdit ? '' : '$dropdown'"
            ></v-autocomplete>
          </v-col>
        </v-col>
        <!-- <v-col cols="12" md="4" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
            >
              Status PTKP
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0;">
            <v-autocomplete
              v-if="result.ptkp_status !== null"
              :items="dropdown.ptkp_status"
              item-text="name"
              item-value="id"
              return-id
              dense
              style="margin: 0"
              v-model="result.ptkp_status.id"
              :append-icon="!isEdit ? '' : '$dropdown'"
            ></v-autocomplete>
            <v-autocomplete
              v-else
              :items="dropdown.ptkp_status"
              item-text="name"
              item-value="id"
              return-object
              dense
              style="margin: 0"
              v-model="result.ptkp_status"
              :append-icon="!isEdit ? '' : '$dropdown'"
            ></v-autocomplete>
          </v-col>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
            >
              NPWP
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0;">
            <v-text-field
              style="height:30px; position:relative; top:-4px;"
              dense
              v-model="result.npwp_no"
            />
          </v-col>
        </v-col> -->
        <v-col cols="12" md="4" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              Golongan darah
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-autocomplete
              :items="dropdown.blood_type"
              dense
              style="margin: 0"
              v-model="result.blood_type"
              :append-icon="!isEdit ? '' : '$dropdown'"
            ></v-autocomplete>
          </v-col>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0 10px">
          <v-col cols="12" style="padding: 0">
            <p
              style="
                margin: 0;
                font-size: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.5);
              "
            >
              Ukuran Baju
            </p>
          </v-col>
          <v-col cols="12" style="padding: 0">
            <v-text-field
              style="height: 30px; position: relative; top: -3px"
              dense
              v-model="result.clothing_size"
            />
          </v-col>
        </v-col>
        <v-col cols="12" style="padding: 0; text-align: right; margin: auto">
          <v-btn
            v-if="isEdit"
            type="submit"
            rounded
            elevation="0"
            color="primary"
            class="white--text font-weight-bold"
            style="font-size: 12px; width: 130px"
            :loading="loading"
          >
            Berikutnya <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <div
      v-else
      class="d-flex justify-center align-center"
      style="width: 80vw; height: 50vh; margin: auto"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        size="100"
      ></v-progress-circular>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import buildType from '../../../../services/buildType'
// import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  computed: {
    // ...mapGetters(['getUserProfile'])
  },
  props: ['result', 'dropdown', 'isEdit', 'permission'],
  data() {
    return {
      hrsApi: buildType.apiURL('hrs'),
      upload: buildType.apiURL('upload2'),
      build: process.env.VUE_APP_BUILD_TYPE,
      // isEdit: false,
      loading: false,

      rules: {
        birthDateRules: [],
        birthPlaceRules: [],
        phoneRules: [],
        addressRules: [],
        nameRules: [],
        ktpRules: [],
        emailRules: [],
        genderRules: [],
        mariageRules: [],
        nikRules: [],
        contractDateRules: [],
        contractMonthRules: [],
        contractExpRules: [],
        companyRules: [],
        departmentRules: [],
        positionRules: [],
        joinDateRules: [],
        employeeStatusRules: []
      }
    }
  },
  watch: {
    isEdit() {
      if (!this.isEdit) {
        this.$emit('cancel')
      }
    }
  },
  methods: {
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    uploadFoto(event) {
      if (event !== null) {
        const data = new FormData()
        data.append('file', event)
        data.append('module', 'photo.employee')
        axios
          .post(`${this.upload}esanqua/hris`, data)
          .then((res) => {
            if (res.data.status === true) {
              this.showMsgDialog('success', res.data.message, false)
              this.result.photo = res.data.data.name
            }
          })
          .catch((err) => {
            this.showMsgDialog(
              'error',
              err
                ? 'Something went wrong, Please contact an admin!'
                : 'Something went wrong, Please contact an admin!',
              false
            )
          })
      } else {
        this.result.photo = ''
      }
    },
    editForm() {
      this.$emit('editForm')
    },
    submit() {
      const self = this
      setTimeout(function () {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      // console.log(this.result)
      // let department = null
      // let xPhoto = ''
      // if (process.env.VUE_APP_BUILD_TYPE === 'development') {
      //   xPhoto = this.result.photo.replace(
      //     'https://dev-esanqua.sanquawater.co.id/image/ecatalogue/hris/photo/employee/',
      //     ''
      //   )
      // } else {
      //   xPhoto = this.result.photo.replace(
      //     'https://e-sanqua.sanquawater.co.id/image/ecatalogue/hris/photo/employee/',
      //     ''
      //   )
      // }
      // if (this.result.department !== null) {
      //   department = this.result.department_id
      // } else {
      //   department = this.result.department
      // }
      // const newForm = {
      //   act: 'update',
      //   id: this.$route.params.id,
      //   nik: this.result.nik,
      //   name: this.result.name,
      //   company_id:
      //     this.result.company !== null ? this.result.company.id : null,
      //   department_id: department,
      //   level_id: this.result.level !== null ? this.result.level.id : null,
      //   card_no: this.result.card_no,
      //   email: this.result.email,
      //   gender: this.result.gender !== null ? this.result.gender.id : null,
      //   religion:
      //     this.result.religion !== null ? this.result.religion.id : null,
      //   birth_place: this.result.birth_place,
      //   birth_date: this.result.birth_date,
      //   no_identitas: this.result.no_identitas,
      //   address: this.result.address,
      //   mobile_phone_no: this.result.mobile_phone_no,
      //   education_level: this.result.education_level,
      //   education_major: this.result.education_major,
      //   mariage_status:
      //     this.result.mariage_status !== null
      //       ? this.result.mariage_status.id
      //       : null,
      //   ptkp_status_id:
      //     this.result.ptkp_status !== null ? this.result.ptkp_status.id : null,
      //   npwp_no: this.result.npwp_no,
      //   gol: this.result.gol,
      //   employee_status_id:
      //     this.result.employee_status !== null
      //       ? this.result.employee_status.id
      //       : null,
      //   status: this.result.status,
      //   join_date: this.convertDate(this.result.join_date),
      //   contract_date: this.convertDate(this.result.contract_date),
      //   contract_month: this.result.contract_month,
      //   expire_contract_date: this.convertDate(
      //     this.result.expire_contract_date
      //   ),
      //   bpjs_no: this.result.bpjs_no,
      //   bpjs_tk_no: this.result.bpjs_tk_no,
      //   blood_type: this.result.blood_type,
      //   domicile_address: this.result.domicile_address,
      //   photo: xPhoto,
      //   fingerprint_id: this.result.fingerprint_id,
      //   // is_shift:
      //   //   this.result.employee_working_hour !== null
      //   //     ? this.result.employee_working_hour.is_shift
      //   //     : false
      //   is_shift: this.result.is_shift
      //   // last_attendance_status: this.result.last_attendance_status
      // }
      // console.log(newForm)
      this.$emit('changeTab', 1)
      // this.save(newForm)
    },

    // async save(form) {
    //   this.loading = true
    //   await axios
    //     .post(`${this.hrsApi}employee/save`, form)
    //     .then(res => {
    //       this.loading = false
    //       if (res.data.status_code !== '-99') {
    //         setTimeout(() => {
    //           this.$emit('cancel')
    //           return this.$emit('editForm')
    //         }, this.showMsgDialog('success', res.data.status_msg, false))
    //       } else {
    //         if (res.data.status_msg === 'Parameter value has problem') {
    //           return this.showMsgDialog(
    //             'error',
    //             'Invalid input, Parameter value has problem',
    //             false
    //           )
    //         } else {
    //           return this.showMsgDialog('error', res.data.status_msg, false)
    //         }
    //       }
    //     })
    //     .catch(err => {
    //       this.showMsgDialog(
    //         'error',
    //         err
    //           ? 'Something went wrong, Please contact an admin!'
    //           : 'Something went wrong, Please contact an admin!',
    //         false
    //       )
    //       return (this.loading = false)
    //     })
    // },
    getAge() {
      let str = ''
      // const now = new Date().toLocaleDateString('id')
      if (this.result.birth_date !== null || this.result.birth_date !== '') {
        // const birth = new Date(this.result.birth_date).toLocaleDateString('id')

        var diff_s = moment(this.result.birth_date).diff(
          moment(),
          'milliseconds'
        )
        var duration = moment.duration(diff_s)
        var year = duration._data.years.toString()
        str = year.replace('-', '')
      }
      return str
    },
    convertDate(val) {
      if (val !== null || val !== '') {
        const rawDate = new Date(val).toLocaleDateString('id').split('/')
        const y = rawDate[2]
        const m = rawDate[1].length === 2 ? rawDate[1] : '0' + rawDate[1]
        const d = rawDate[0].length === 2 ? rawDate[0] : '0' + rawDate[0]
        const newDate = `${y}-${m}-${d}`
        return newDate
      }
      return ''
    }
  }
}
</script>
